.Projects--container {
  width: 100%;
  max-width: 100%;
  flex-direction: row;
}

.Projects--title {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    text-align: center;
}

.Projects--projects-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1em 5%;
    flex: 1;
}

.Projects--project-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 1rem;
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    flex: 1;
    flex-basis: 25%;
}

.Projects--project-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.Projects--project-header-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 1rem;
}

.Projects--project-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    flex: 1;
}

.Projects--project-title {
    font-size: 2em;
}

.Projects--project-description {
    font-size: 1.2em;
    text-align: center;
}

.Projects--project-technologies {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
    flex: 1;
}

.Projects--project-technology {
    padding: 0.5rem 1rem;
    margin: 0 0.2em;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 1.2em;
}

.Projects--project-links{
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
    bottom: 0;
}

.Projects--project-link{
    padding: 0.5rem 1rem;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 1.2em;
    text-decoration: none;
    color: black;
    transition: all 0.3s ease;
}

.Projects--project-link:hover {
    background-color: #e0e0e0;
}

@media screen and (max-width: 768px) {
    .Projects--projects-container{
        flex-direction: column;
    }
    
}