.App {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App--header {
    width: 100%;
    height: auto;
}