.skills-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;

}

.skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px 20px;
}

.skill-box {
    display: flex;
    color: white;
    flex-basis: 29%;
    gap: 1em;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 5px 1em;
    background-color: #030F12;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 80%;
}

.skill-name {
    letter-spacing: 0.05em;
    min-width: 100px;
    text-transform: uppercase;
}

.skill-image {
    width: 100px;
    height: 100px;
    flex-wrap: wrap;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    border-radius: 20%;
    transition: transform 1s;
}

.skill-box:hover{
    transform: translate(0, -10px);
    transition: transform 0.3s;

}

.skill-image {
    transform: scale(1.2) rotate(360deg);
    transition: scale 0.3s rotate 0.3s;
}

.skill-level-bar {
    background-color: white;
    
    height: 15px;
    border-radius: 5px;
    flex: 1;
}


.skill-level-bar-inside {
    background-color: #64D2E8;
    height: 100%;
    border-radius: 5px;
    transition: width 1s;
}

@media (max-width: 768px) {
    .skill-box {
        flex-basis: 100%;
    }
}