.AboutMe--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    margin: 0;
    margin-top: 2em;
    padding: 0 5%;
    max-width: 100%;
    min-height: 100vh;
}

.AboutMe--container .title {
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.AboutMe--container .information {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
}

.AboutMe--container .about {
    display: flex;
    font-size: 1.1em;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
}

@media screen and (max-width: 768px) {
    .AboutMe--container {
        font-size: smaller;
    }

    .AboutMe--container .information {
        flex-direction: column;
    }

    .AboutMe--container .information img {
        width: 20em;
        margin: 0 20px;
    }

    .AboutMe--container .information .about {
        margin: 0 3em;
    }

    .AboutMe--container .information .about .title {
        margin-top: 1em;
    }
}



.AboutMe--container .about .title {
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.AboutMe--container .information img {
    width: 80%;
    margin: 0 20px;
}

.AboutMe--container .information .about {
    margin: 0 3em;
}

.AboutMe--container .information .about .title {}

.AboutMe--container .information .about .text {}

.AboutMe--container .skills-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}

.AboutMe--container .skills-wrapper .title {
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.AboutMe--container .skills-wrapper .skills {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-evenly;
    margin: 0 auto;
    width: 100%;
}

.AboutMe--container .skills-wrapper .skills .skills-list {}
