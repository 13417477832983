.Footer--container {
    background-color: #000;
    color: #fff;
    padding: 20px 0; /* Reduced padding to make the footer shorter */
    width: 100%;
}

.Footer--content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.Footer--copyright {
    font-size: 14px; /* Slightly reduced font size */
}

.Footer--social-links {
    display: flex;
    gap: 25px; /* Increased gap for larger icons */
}

.Footer--social-icon {
    cursor: pointer;
    color: #fff;
    transition: color 0.3s ease;
}

.Footer--social-icon:hover {
    color: #007bff;
}

@media (max-width: 768px) {
    .Footer--content {
        flex-direction: column;
        gap: 15px; /* Reduced gap for mobile */
    }

    .Footer--copyright {
        text-align: center;
    }
}