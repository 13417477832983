.StarCanva--container {
    color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.StarCanva--container .canvas {
    width: 100%;
    height: 100vh;
    background: #353a47;
    background: radial-gradient(circle, #353a47 0%, #15213e 50%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.StarCanva--container .text {
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.StarCanva--container .text .name {
    color: #7EDAEC;
}

@media screen and (max-width: 768px) {
    .StarCanva--container .text {
        font-size: 2rem;
    }
}