.Navbar-container {
    display: flex;
    gap: 5rem;
    background-color: #030F12;
    color: #fff;
    padding: 1rem 7em;
    border-bottom: 3px solid #64D2E8;
    position: sticky;
    top: 0;
    z-index: 1;
}

.Navbar-container .button {
    text-transform: uppercase;
    font-size: 1.1rem;
    background-color: #030F12;
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    letter-spacing: 0.08em;
}


.Navbar-container .button:hover {
    color: #E41B6F;
}

.Navbar-container .small-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 2em;
    width: auto;
    align-items: center;
}

.Navbar-container .burger-menu {
    display: none;
    transition: height 0.3s ease-in-out;
    height: 0px;
}

.Navbar-container .burger-menu.active {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0.3rem;
}

@media screen and (max-width: 420px) {
    .Navbar-container .small-navbar-container {
        padding: 0em;
    }
}

.Navbar-container .small-navbar-container .name {
    font-size: 1.2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

.nav-buttons {
    display: flex;
    gap: 1rem;
}

@media screen and (max-width: 1024px) {
    .Navbar-container {
        gap: 3rem;
        padding: 1rem 5em;
    }
}

@media screen and (max-width: 768px) {
    .Navbar-container {
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 3em;

    }
}