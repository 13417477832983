/* Container for the hackathons section */
.Hackathons--container {
    padding: 20px 0;
    /* background-color: #f5f5f5; */
    text-align: center;
    width: 100%;
}

/* Title styling */
.Hackathons--title {
    font-size: 36px;
    font-weight: bold;
    margin: 0px;
}

/* Swiper container */
.Hackathons--slider-container {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

/* Swiper specific styles */
.Hackathons--swiper {
    width: 100%;
    height: auto;
}

.Hackathons--swiper-slide {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 20px 0px;
}

/* Project container styling */
.Hackathons--project-container {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    width: 80%;
    max-width: 90%;
    aspect-ratio: auto;
    text-align: left;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 400px;
}

.Hackathons--project-container:hover {
    transform: translateY(-10px);
}

/* Project image styling */
.Hackathons--project-header-image {
    width: 100%;
    height: 35%;
    object-fit: cover;
    border-bottom: 2px solid #ddd;
    flex: 0 0 auto;
}

/* Project info styling */
.Hackathons--project-info {
    padding: 20px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: hidden;
}

.Hackathons--project-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.Hackathons--project-description {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

.swiper-pagination {
    position: relative;
    margin-top: 20px;
    bottom: 0;
}

/* Swiper pagination (bullets) */
.swiper-pagination-bullet {
    background-color: #888;
    opacity: 0.7;
    width: 10px;
    height: 10px;
    margin: 0 5px;
}

.swiper-pagination-bullet-active {
    background-color: #000;
    opacity: 1;
}

/* Swiper navigation buttons */
.swiper-button-prev,
.swiper-button-next {
    color: #000;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    background-color: rgba(255, 255, 255, 1);
}

/* Responsive styling */
@media (max-width: 768px) {
    .Hackathons--project-container {
        max-width: 90%;
        aspect-ratio: auto;
        height: 400px;
    }

    .Hackathons--project-header-image {
        flex: 1;
    }

    .Hackathons--project-title {
        font-size: 20px;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }

    .Hackathons--project-description {
        font-size: 14px;
    }
}
